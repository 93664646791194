import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Label,
  Input,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { displayError, displaySuccess, validateEmail } from '../../../Utils';
import { createStore } from '../../../rootStore';

function LowRatingFormHTML(props) {
  const { modal, toggle } = props;

  const [lowRatingForm, setLowRatingForm] = useState({
    name: '', email: '', phone: '', message: '',
  });

  const store = createStore();
  const { pageData: { instance, site } } = store.getState();

  const onHandleChange = (e) => {
    const { target: { name, value } } = e;
    setLowRatingForm({ ...lowRatingForm, [name]: value });
  };

  const onSubmitForm = () => {
    const {
      name, email, phone, message,
    } = lowRatingForm;
    if (name === '') {
      displayError('Please enter your name.');
    } else if (!validateEmail(email)) {
      displayError('Please enter a valid email address.');
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('message', message);
      const queryString = new URLSearchParams(formData).toString();
      fetch(`/api/v1/instances/${instance?.id}/sites/${site?.id}/negative_review_form?${queryString}`, {
        method: 'POST',
      }).then((response) => {
        response.json().then(() => {
          toggle(false);
          setLowRatingForm({});
          displaySuccess('Your message has been submitted. Thank you for your feedback!');
        });
      });
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => toggle(false)}>
      <ModalBody>
        <p>We strive for 100% customer satisfaction. If we fell short, please tell us more so we can address your concerns.</p>
        <Row>
          <Col>
            <Label for="review-name">
              Your name
              <b>*</b>
            </Label>
            <Input
              type="text"
              className="form-control"
              id="review-name"
              name="name"
              placeholder="Your name"
              value={lowRatingForm?.name || ''}
              onChange={(e) => onHandleChange(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="review-phone">Phone</Label>
            <Input
              type="text"
              className="form-control"
              id="review-phone"
              name="phone"
              placeholder="Phone"
              value={lowRatingForm?.phone || ''}
              onChange={(e) => onHandleChange(e)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="review-email">
              Email
              <b>*</b>
            </Label>
            <Input
              type="text"
              className="form-control"
              id="review-email"
              name="email"
              placeholder="Email"
              value={lowRatingForm?.email || ''}
              onChange={(e) => onHandleChange(e)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Label for="review-message">Message</Label>
            <Input
              type="textarea"
              className="form-control"
              id="review-message"
              name="message"
              placeholder="Message"
              value={lowRatingForm?.message || ''}
              onChange={(e) => onHandleChange(e)}
            />
          </Col>
        </Row>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              toggle(false);
              setLowRatingForm({});
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => onSubmitForm()}>Send Message</Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

export default LowRatingFormHTML;
