import { PaginationProps } from '../../ts/interfaces';
import Base from './Base';

interface Datum {
  key:   string;
  value: Array<number | string>;
}

interface Stats {
  [key: string]: any;
}

interface MemberProps {
  id?:       string;
  fullname?: string;
  email?:    string;
  data?:     Datum[];
  stats?:    Stats;
}

export default class MemberList extends Base {
  members?:    MemberProps[];
  pagination?: PaginationProps;

  constructor(props) {
    super(props);
    this.members = props.members;
    this.pagination = props.pagination;
  }
}
