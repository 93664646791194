import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

export function FrameBox({ url, modal, toggle }) {
  return (
    <Modal isOpen={modal} toggle={() => toggle(false)} size="xl">
      <ModalBody>
        <iframe src={url} id="framebox" width="100%" />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => toggle(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

export function ImagePreview({ item, modal, toggle }) {
  return (
    <Modal isOpen={modal} toggle={() => toggle(false)} size="xl">
      <ModalBody className="text-center">
        <img
          src={item?.image_url}
          alt={item?.alt}
          width={item?.width_click_to_zoom ? item?.width_click_to_zoom : ''}
          style={{ maxWidth: '100%' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => toggle(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
