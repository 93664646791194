import Base from './Base';

interface SiteBriefingInfo {
  include_about_page:        boolean;
  include_contact_page:      boolean;
  contact_form_above_footer: boolean;
  product_pages:             any[];
};

class SiteBriefing extends Base {
  id?:                   string;
  site_id?:              string | number;
  site_created_at?:      null;
  info?:                 SiteBriefingInfo;
  project_manager_name?: null;
  adviser_name?:         null;
  content_created?:      boolean;

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
};

export default SiteBriefing;
