import Base from './Base';
import { BreakPoint } from './Section';

interface TextStyleProps {
  id?:              string;
  name?:            string;
  type?:            string;
  fonts?:           string;
  fallback_font?:   string;
  tag?:             string;
  color?:           string;
  link_color?:      string;
  font_weight?:     string;
  font_size?:       number;
  line_height?:     number;
  text_decoration?: string;
  font_style?:      string;
  text_align?:      string;
  site_id?:         string;
  class_name?:      string;
  break_point?:     BreakPoint;
}

class TextStyle extends Base {
  id?:              string;
  name?:            string;
  type?:            string;
  fonts?:           string;
  fallback_font?:   string;
  tag?:             string;
  color?:           string;
  link_color?:      string;
  font_weight?:     string;
  font_size?:       number;
  line_height?:     number;
  text_decoration?: string;
  font_style?:      string;
  text_align?:      string;
  site_id?:         string;
  class_name?:      string;
  break_point?:     BreakPoint;

  constructor(props: TextStyleProps = {}) {
    super(props);
    Object.assign(this, props);
  }
}

export default TextStyle;
