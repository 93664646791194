import { PaginationProps } from '../../ts/interfaces';
import Base from './Base';

interface CTALogsProps {
  data?: CtaLogData[];
}

interface CtaLogData {
  id?:         string;
  type?:       string;
  attributes?: Attributes;
}

interface Attributes {
  done?:        boolean;
  site?:        Site;
  created_at?:  Date;
  type?:        string;
  full_name?:   string;
  spam?:        boolean;
  data?:        Attributes[];
  page?:        Page;
  attachments?: Attachment[];
}

interface Attachment {
  id?:       string;
  filename?: string;
}

interface Attributes {
  key?:   string;
  value?: string[];
}

interface Page {
  page_name?:      string;
  sector_name?:    string;
  section_number?: number;
}

interface Site {
  site_id?:  string | number;
  hostname?: string;
}

class CTALog extends Base {
  cta_log?: CTALogsProps[];
  pagination?: PaginationProps;
  count_by_type?: object;

  constructor(props) {
    super(props);
    this.cta_log = props.cta_log;
    this.pagination = props.pagination;
    this.count_by_type = props.count_by_type;
  }
}

export default CTALog;
