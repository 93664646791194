import Base from './Base';

interface BackendErrorLogProps {
  id?:              string;
  date?:            Date;
  category?:        string;
  text?:            string;
  instance_name?:   string;
  domain?:          string;
  site_id?:         string | number;
  instance_id?:     string | number;
}

class BackendErrorLogs extends Base {
  id?:              string;
  date?:            Date;
  category?:        string;
  text?:            string;
  instance_name?:   string;
  domain?:          string;
  site_id?:         string | number;
  instance_id?:     string | number;

  constructor(props: BackendErrorLogProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default BackendErrorLogs;
