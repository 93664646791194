import React from 'react';

const FullScreenSpinner = () => {
  return (
    <div id="fullScreenSpinner" className="show">
      <i className="fa-regular fa-spinner fa-spin" />
    </div>
  );
};

export default FullScreenSpinner;
