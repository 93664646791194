import Base from './Base';
import ContentTopic from './ContentTopic';

class ContentTopicBriefing extends Base {
  id?:                              string;

  site_id?:                         string | number;
  business_name?:                   string;
  business_type?:                   string;
  service_location?:                string;
  target_keywords?:                 string;
  products_services?:               string;
  existing_product_service_pages?:  string;
  new_product_ideas?:               string;
  new_article_ideas?:               string;
  existing_website_content?:        string;
  home_page_content?:               string;
  about_page_content?:              string;
  other_site_content?:              string;
  other_info?:                      string;
  content_tone?:                    string;
  content_tone_other?:              string;
  content_topics?:                  ContentTopic[];

  constructor(props: object) {
    super(props);
  }
}

export default ContentTopicBriefing;
