import Base from './Base';

class UserRecentItem extends Base {
  path?:        string;
  instance_id?: string | number;
  site_id?:     string | number;
  type?:        string;
  item_id?:     string | number;
  ts?:          number;

  constructor(props) {
    super(props);

    this.path = props.path || '';
  }
}

export default UserRecentItem;
