import { useEffect, useState } from 'react';
import { useAppSelector } from '../rootStore';

const offset = (el: HTMLAnchorElement) => {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX,
  };
};

// Because we are rendering the page after it is ready, scrolling to anchor links doesn't work like it would if the
// page was a normal HTML page, so we do it manually.
const ScrollToAnchorLink: React.FC = () => {
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const redirectSectionURLs = useAppSelector((state) => state.pageData.redirect_section_urls);

  useEffect(() => {
    if (typeof (window as any).performance === 'undefined') {
      return;
    }

    // TODO: These are not firing, need to figure out why. I suspect it's a scope issue, need to bind the functions.
    window.addEventListener('wheel', () => setUserHasScrolled(true));
    window.addEventListener('touchmove', () => setUserHasScrolled(true));

    // A map of element IDs to their top position.
    const elementTops = {};

    const handler = setInterval(() => {
      const timeSinceNavigation = performance.now();

      // If 10 seconds has passed since the navigation started, there is probably some bigger problem with the page,
      // don't want to keep doing this forever.
      if (timeSinceNavigation > 10000) {
        clearInterval(handler);
        return;
      }

      // If the user has initiated a scroll, we don't want to interfere with what they're doing, so just leave it alone.
      if (userHasScrolled) {
        clearInterval(handler);
        return;
      }

      let el: HTMLAnchorElement | null = null;
      const pathnameWithoutLeadingSlash = window.location.pathname.replace('/', '');

      if (location.hash) {
        try {
          el = document.querySelector(location.hash);
        } catch (e) {
          if (e instanceof DOMException) {
            // This is probably a hash which is not a valid ID selector, this happens for Ecwid, and possibly other scenarios.
            return;
          }
        }
      } else if (redirectSectionURLs && Object.keys(redirectSectionURLs).includes(pathnameWithoutLeadingSlash)) {
        el = document.querySelector(`#anchor${redirectSectionURLs[pathnameWithoutLeadingSlash]}`);
      }

      if (!el) {
        clearInterval(handler);
        return;
      }

      const top = offset(el).top;
      if (typeof elementTops[el.id] === 'undefined' || top > elementTops[el.id]) {
        elementTops[el.id] = top;
        window.scrollTo(0, top);
      }
    }, 50);
  }, []);

  return null;
};

export default ScrollToAnchorLink;
