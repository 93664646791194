import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useAppSelector } from '../rootStore';

const AddImageAltTags = () => {
  const { guaranteed_keyword_phrases: phrases } = useAppSelector((state) => state.pageData.site) as {
    guaranteed_keyword_phrases: string[],
  };

  useEffect(() => {
    if (isEmpty(phrases)) {
      return;
    }
    document.addEventListener('render', () => {
      const usedPhrases: string[] = [];

      ([...document.querySelectorAll('img:not([alt]), img[alt=""]')] as HTMLImageElement[]).forEach((img) => {
        // We have some situations where we don't want alt tags added, eg when image galleries make alt tags visible.
        if (img.closest('.disable-auto-alt-tags')) {
          return;
        }

        const phrase = phrases.find((p) => usedPhrases.indexOf(p) === -1);
        if (!phrase) {
          return;
        }

        usedPhrases.push(phrase);

        img.alt = phrase;
      });
    });
  }, []);

  return <></>;
};

export default AddImageAltTags;
