import Base from './Base';

class SavedSearch extends Base {
  id?:    string;
  name?:  string;

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
}

export default SavedSearch;
