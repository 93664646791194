import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

// Handle clicks on images set as clickable thumbnails. Would normally add an event handler in TextBlockMedia but its
// elements are recreated by SectionContent so we can't add event handlers there.
const ClickableThumbnails = () => {
  const [modal, setModal] = useState(false);
  const [url, setURL] = useState(null);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!e.target.matches('.clickable-thumbnail')) {
        return;
      }

      const src = e.target.dataset.src;
      if (!src) {
        return;
      }

      setURL(src);
      setModal(true);
    })
  }, []);

  if (!modal) {
    return <></>;
  }

  return (
    <Modal
      isOpen={modal}
      size="lg"
      centered
      style={{ maxWidth: '80%' }}
      toggle={() => setModal(!modal)}
    >
      <ModalBody
        className="text-center"
      >
        <img
          src={url}
          style={{ maxWidth: '100%', maxHeight: '80vh' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          data-bs-dismiss="modal"
          data-dismiss="modal"
          onClick={() => setModal(false)}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClickableThumbnails;
