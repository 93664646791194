import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStore } from '../rootStore';

function AddCustomCode() {
  const store = createStore();
  const [readyCodeRun, setReadyCodeRun] = useState(false);
  const [loadCodeRun, setLoadCodeRun] = useState(false);
  const { pageData: { custom_codes: customCodes } } = store.getState();
  const templateLoaded = useSelector((state) => state.pageData.template_loaded);

  useEffect(() => {
    const jsCodes = customCodes.filter(
      (item) => item.language === 'javascript',
    );
    jsCodes.forEach((custom_code) => {
      if (custom_code.code_position === 'ready' && templateLoaded && !readyCodeRun) {
        (new Function(custom_code.code))();
        document.dispatchEvent(new Event('render'));
        setReadyCodeRun(true);
      } else if (custom_code.code_position === 'load' && !loadCodeRun) {
        setLoadCodeRun(true);
        window.addEventListener('load', () => {
          if (!loadCodeRun) {
            (new Function(custom_code.code))();
            document.dispatchEvent(new Event('render'));
            setLoadCodeRun(true);
          }
        });
      }
    });
  }, [customCodes, templateLoaded]);

  return null;
}

export default AddCustomCode;
