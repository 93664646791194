import React from 'react';

function ReviewAverage({ rating }) {
  return (
    <div className="stars-cont">
      <div className="stars stars-grey">
        <div className="stars stars-gold" style={{ width: `${rating * 20}%` }}>
          <i className="fas fa-star" aria-hidden="true"></i>
          <i className="fas fa-star" aria-hidden="true"></i>
          <i className="fas fa-star" aria-hidden="true"></i>
          <i className="fas fa-star" aria-hidden="true"></i>
          <i className="fas fa-star" aria-hidden="true"></i>
        </div>
        <i className="fas fa-star" aria-hidden="true"></i>
        <i className="fas fa-star" aria-hidden="true"></i>
        <i className="fas fa-star" aria-hidden="true"></i>
        <i className="fas fa-star" aria-hidden="true"></i>
        <i className="fas fa-star" aria-hidden="true"></i>
      </div>
    </div>
  );
}

export default ReviewAverage;
