import Base from './Base';

interface CustomCodeProps {
  id?:            string;
  file_name?:     string;
  language?:      string;
  code?:          string;
  code_position?: string;
  page_ids?:      string[];
  template_ids?:  string[];
  site_id?:       string | number;
}

class CustomCode extends Base {
  id?:            string;
  file_name?:     string;
  language?:      string;
  code?:          string;
  code_position?: string;
  page_ids?:      string[];
  template_ids?:  string[];
  site_id?:       string | number;

  constructor(props: CustomCodeProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default CustomCode;
