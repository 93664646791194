import React, { useState, useEffect, useMemo } from 'react';
import { AiChat } from '@nlux/react';
import { useInstance, useSite, useFireRenderEvent } from '../../common/hooks';
import '@nlux/themes/nova.css';

function ActionPageInstantContactChat() {
  const makeStreamAdapter = (instance, site, threadId) => ({
    streamText: async (prompt, observer) => {
      const body = { prompt, thread_id: threadId };
      const response = await fetch(`/api/v1/instances/${instance.id}/sites/${site.id}/chats`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        observer.error(new Error('Failed to connect to the server'));
        return;
      }

      if (!response.body) {
        return;
      }

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        const content = textDecoder.decode(value);
        if (content) {
          observer.next(content);
        }
      }

      observer.complete();
    },
  });

  const instance = useInstance();
  const site = useSite();

  const [showTerms, setShowTerms] = useState(false);

  const [threadId, setThreadId] = useState(null);
  const adapter = useMemo(() => makeStreamAdapter(instance, site, threadId), [instance, site, threadId]);

  useEffect(() => {
    if (!instance || !site) {
      return;
    }

    if (threadId || !site?.enable_instant_contact_form_chat) {
      return;
    }

    (async () => {
      const response = await fetch(`/api/v1/instances/${instance.id}/sites/${site.id}/chats`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      setThreadId((await response.json()).data);
    })();
  }, [instance, site]);

  // This is ugly... but nlux's built-in autoFocus is not working for me (MP)
  useEffect(() => {
    window.focusInterval = setInterval(() => {
      const textarea = document.querySelector('.nlux-comp-composer textarea');
      if (textarea) {
        textarea.focus();
        clearInterval(window.focusInterval);
      }
    }, 250);
  }, []);

  useFireRenderEvent();

  if (!site?.enable_instant_contact_form_chat) {
    // If we don't return anything here the sector might be hidden by HideEmptySectors.
    return (
      <div className="action-page" />
    );
  }

  return (
    <div className={`action-page style-${site?.instant_contact_chat_text_style_id}`}>
      <div className="chatbot">
        <AiChat
          displayOptions={{
            height: '400px',
          }}
          composerOptions={{
            autoFocus: true,
            placeholder: (
              site.chatbot_persona_name ? `Message ${site.chatbot_persona_name}...` : 'Say something...'
            ),
          }}
          personaOptions={{
            assistant: {
              name: site.chatbot_persona_name,
              avatar: `/c/${site.chatbot_persona_icon_id}/avatar-icon`,
            },
          }}
          initialConversation={[
            {
              role: 'assistant',
              message: site.chatbot_initial_message,
            },
          ]}
          adapter={adapter}
        />
      </div>

      <small className="chat-terms">
        <div className="text-end">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowTerms(!showTerms);
            }}
          >
            Terms
            {' '}
            <i className={`fa fa-chevron-${showTerms ? 'up' : 'down'}`} />
          </a>
        </div>
        {showTerms && (
          <div className="mt-2">
            <p>
              The following disclaimer is intended to inform users of the limitations and usage guidelines of this AI chatbot. Please read this disclaimer carefully before engaging with the chatbot.
            </p>
            <p>
              General Information: The AI chatbot is an automated system powered by OpenAI. It is designed to provide information and assistance based on the public documentation on this website. While this chatbot is designed to provide accurate information about
              {' '}
              {site.display_name}
              , there is no guarantee as to the accuracy, completeness, or up-to-date nature of any of the information provided. We recommend that you use responses as a starting point only and verify the information by contacting
              {' '}
              {site.display_name}
              {' '}
              directly.
            </p>
            <p>
              User Responsibility: Users of this AI chatbot bear sole responsibility for their interactions and reliance on the information provided. It is important to exercise caution and use your discretion while interpreting and acting upon the chatbot&apos;s responses. We cannot be held liable for any actions, losses, or damages resulting from the use of the chatbot.
            </p>
            <p>
              Data Privacy and Security: We prioritise the privacy and security of our users&apos; information. Be aware that chatbot conversations may be reviewed by Web Genius to improve results. OpenAI may retain logs of these conversations, and this data may be used to train their AI models. Web Genius does not share any sensitive or personal information in your conversations with any other parties.
            </p>
            <p>
              By using this AI chatbot, you indicate your acceptance and understanding of the above disclaimer. If you do not agree with any part of this disclaimer, you should refrain from using the chatbot. For further assistance or enquiries, please contact
              {' '}
              <a href="mailto:support@webgenius.co.nz">support@webgenius.co.nz</a>
              .
            </p>
          </div>
        )}
      </small>
    </div>
  );
}

export default ActionPageInstantContactChat;
