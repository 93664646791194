import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { createStore } from '../../../rootStore';

const platformName = (platform) => {
  switch (platform) {
    case 'google':
      return 'Google';
    case 'facebook':
      return 'Facebook';
    case 'nocowboys':
      return 'No Cowboys';
    default: return '';
  }
};

const platformURL = (platform, url) => {
  switch (platform) {
    case 'google':
      return `https://search.google.com/local/writereview?placeid=${url}`;
    case 'facebook':
      return url;
    case 'nocowboys':
      return `${url}#rate`;
    default: return '';
  }
};

function HighRatingFormHTML({ modal = false, toggle }) {
  const store = createStore();
  const { pageData: { site: { review_pages } } } = store.getState();
  const reviewPages = Object.keys(review_pages).filter((e) => review_pages[e] !== '');

  useEffect(() => {
    if (modal && reviewPages.length === 1) {
      setTimeout(() => {
        window.open(platformURL(reviewPages[0], review_pages[reviewPages[0]]));
      }, 3000);
    }
  }, [modal, reviewPages]);

  const message = () => {
    if (reviewPages.length > 1) {
      return 'Would you share your experience on one of these sites?';
    }
    if (reviewPages.length === 1) {
      return `You will shortly be redirected to ${platformName(reviewPages[0])}. Please place your review there.`;
    }
    return '';
  };

  const platformHTML = () => reviewPages.map((page) => {
    if (review_pages[page] !== '') {
      return (
        <div className="review-link-wrap" key={page}>
          <a data-platform={page} target="_blank" href={platformURL(page, review_pages[page])} className="d-block" rel="noreferrer">
            <div className={`review-source review-source-large review-source-${page} d-block`} />
            {platformName(page)}
          </a>
        </div>
      );
    }
    return null;
  });

  return (
    <Modal isOpen={modal} toggle={() => toggle(false)}>
      <ModalBody>
        <p>
          Thank you!
          {' '}
          <strong>We need your help.</strong>
          {' '}
          {message()}
        </p>
        <div id="review-links">
          {platformHTML()}
        </div>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => toggle(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

HighRatingFormHTML.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default HighRatingFormHTML;
