import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector, Provider, useDispatch } from 'react-redux';
import Sector from './Sector';
import { isEmpty } from 'lodash-es';
import macroReplacerNode from './macroReplacerNode';
import { flushSync } from 'react-dom';
import { createStore } from '../rootStore';
import { setPageData } from '../common/api/apiSlice';

const TemplateHTML = ({ template }) => {
  const store = createStore();
  const dispatch = useDispatch();

  const sectors = useSelector((state) => state.pageData.sector);
  const templateFragments = useSelector((state) => state.pageData.template_fragment);

  const cleanLabel = (l) => l.toString().trim().replace(/\s+/g, '');

  useEffect(() => {
    // Need to use setTimeout here because React doesn't like flushSync being used in a useEffect.
    setTimeout(() => {
      flushSync(() => {
        document.querySelectorAll('div.sector').forEach((div) => {
          if (isEmpty(div.dataset.label)) {
            return;
          }

          const sectorLabel = cleanLabel(div.dataset.label);
          const sector = sectors.find((s) => s.label === sectorLabel);

          if (!sector) {
            return;
          }

          const reactNode = document.createElement('div');
          reactNode.classList.add('react-node');
          const root = createRoot(reactNode);
          root.render(
            <Provider store={store}>
              <div className={`sector sector-${cleanLabel(sector.label)} ${sector.is_primary ? 'primary-sector' : ''}`}>
                <Sector sector={sector} />
              </div>
            </Provider>
          );

          div.replaceWith(reactNode);
        });
      });

      // Remove the surrounding <div> around React nodes -- we don't need it, and it is inconsistent with the grid template behaviour.
      document.querySelectorAll('.react-node').forEach((node) => {
        node.replaceWith(...node.childNodes);
      });

      macroReplacerNode();

      dispatch(setPageData({ template_loaded: true }));
    }, 1);
  }, []);

  let templateHTML = template.compiled_html;

  // Replace template fragments
  templateHTML = templateHTML.replaceAll(/\{\{\{fragment-([^}]+)\}\}\}/ig, (_match, fragmentName) => {
    const fragment = templateFragments?.find((f) => f.name === fragmentName);
    if (!fragment) {
      return '';
    }

    return fragment.rendered_html;
  });

  // Replace sectors with placeholder elements
  templateHTML = templateHTML.replaceAll(/\{\{\{sector-([^}]+)\}\}\}/ig, (_match, sectorLabel) => {
    return `<div class=sector data-label="${sectorLabel}"></div>`;
  });

  const parser = new DOMParser();
  const doc = parser.parseFromString(templateHTML, 'text/html');
  const body = doc.querySelector('body');

  // We may have <style> tags in the <head>. We are only using the <body> contents so we need to move the style tags into the body.
  doc.querySelectorAll('head style').forEach((styleNode) => body.appendChild(styleNode));

  return (
    <div
      dangerouslySetInnerHTML={{ __html: body.innerHTML }}
    />
  );
};

export default TemplateHTML;
