import getMacros from './getMacros';

// Replace macros in a string
const macroReplacerString = (s) => {
  const macros = getMacros();

  let replaced = '' + s;

  // Currently we don't do any HTML escaping here.
  macros.filter((m) => !m[2]).forEach(([macro, replacement]) => {
    replaced = replaced.replaceAll(macro, replacement);
  });

  return replaced;
};

export default macroReplacerString;
