import Base from './Base';

interface SavedReportProps {
  id?: string | number;
  user_id?: string;
  name?: string;
  details?: SavedReportDetails;
}

interface SavedReportDetails {
  currentReport?: string;
  columnFilters?: any;
  columnVisibility?: any;
  sorting?: any;
}

class SavedReport extends Base {
  id?: string;
  user_id?: string;
  name?: string;
  details?: SavedReportDetails;

  constructor(props: SavedReportProps) {
    super(props);
  }
}

export default SavedReport;
