import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { createStore } from '../rootStore';
import { useUserConditional } from 'common/hooks';

const GoogleAnalytics: React.FC = () => {
  const user = useUserConditional();

  useEffect(() => {
    // We don't add Analytics if the user is logged into admin. This is so that we don't pollute the site's stats.
    if (user !== false) {
      return;
    }

    const store = createStore();
    const { pageData: { site } } = store.getState();

    if (isEmpty(site.google_analytics_data_stream_id)) {
      return;
    }

    ((s, o, g) => {
      const a = s.createElement(o) as HTMLScriptElement;
      const m = s.getElementsByTagName(o)[0];
      a.async = true;
      a.src = g;
      m.parentNode?.insertBefore(a, m);
    })(document, 'script', `//www.googletagmanager.com/gtag/js?id=${site.google_analytics_data_stream_id}`);

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).gtag = function () {
      // I (MP) tried this:
      // (window as any).gtag = function (...args: any[]) {
      //   (window as any).dataLayer.push(args);
      // but it caused GA to not collect any data, not sure why.

      // eslint-disable-next-line prefer-rest-params
      (window as any).dataLayer.push(arguments);
    };
    (window as any).gtag('js', new Date());

    (window as any).gtag('config', site.google_analytics_data_stream_id, {
      anonymize_ip: true,
      site_speed_sample_rate: 100,
    });
  }, [user]);

  return null;
};

export default GoogleAnalytics;
