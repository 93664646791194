import Base from './Base';

interface CustomTooltipProps {
  id?: string | number;
  [key: string]: any; // Add more properties as needed
}

class CustomTooltip extends Base {
  id?: string;

  constructor(props: CustomTooltipProps) {
    super(props);
    this.id = String(props.id);
  }
}

export default CustomTooltip;
