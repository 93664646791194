import { useEffect } from 'react';

const HideEmptySectors: React.FC = () => {
  const hideWrapper = (w: HTMLElement) => {
    w.style.display = 'none';

    const gridArea = getComputedStyle(w).gridArea;

    // Check if there is also an overlay wrapper for this sector, and hide it too.
    document.querySelectorAll<HTMLElement>('.wrapper-overlay').forEach((overlayWrapper) => {
      const overlayGridArea = getComputedStyle(overlayWrapper).gridArea;
      if (gridArea === overlayGridArea) {
        overlayWrapper.style.display = 'none';
      }
    });
  };

  const showWrapper = (w: HTMLElement) => {
    w.style.display = 'block';

    const gridArea = getComputedStyle(w).gridArea;

    // Check if there is also an overlay wrapper for this sector, and show it too.
    document.querySelectorAll<HTMLElement>('.wrapper-overlay').forEach((overlayWrapper) => {
      const overlayGridArea = getComputedStyle(overlayWrapper).gridArea;
      if (gridArea === overlayGridArea) {
        overlayWrapper.style.display = 'block';
      }
    });
  };

  useEffect(() => {
    const handleRenderEvent = () => {
      document.querySelectorAll<HTMLElement>('.wrapper:not(.wrapper-overlay)').forEach((w) => {
        if (w.querySelectorAll('section, .tb, .precompiled-html, .action-page').length === 0) {
          hideWrapper(w);
        } else if (w.style.display === 'none') {
          showWrapper(w);
        }
      });
    };

    document.addEventListener('render', handleRenderEvent as EventListener);

    return () => {
      document.removeEventListener('render', handleRenderEvent as EventListener);
    };
  }, []);

  return null;
};

export default HideEmptySectors;
