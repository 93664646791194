import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useAppSelector } from '../rootStore';

const ReCAPTCHA: React.FC = () => {
  const templateLoaded = useAppSelector((state) => state.pageData.template_loaded);
  const { recaptcha_site_key } = useAppSelector((state) => state.pageData.site);

  useEffect(() => {
    if (!templateLoaded) {
      return;
    }

    if (isEmpty(recaptcha_site_key)) {
      return;
    }

    const loadReCAPTCHA = () => {
      if (document.querySelector('#wg_recaptcha')) {
        return;
      }
      const s = document.createElement('script');
      s.id = 'wg_recaptcha';
      s.src = 'https://www.google.com/recaptcha/enterprise.js?onload=reCAPTCHAOnloadCallback&render=' + recaptcha_site_key;
      const otherS = document.getElementsByTagName('script')[0];
      otherS.parentNode?.insertBefore(s, otherS);
    };

    (window as any).reCAPTCHAOnloadCallback = () => {
      const getReCaptchaToken = () => {
        (window as any).grecaptcha.enterprise.execute(recaptcha_site_key, { action: 'submit' }).then((token) => {
          const recaptcha = document.querySelectorAll('form .recaptcha_token');
          if (recaptcha.length == 0) {
            document.querySelectorAll<HTMLFormElement>('.form-section').forEach((form) => {
              const tokenInput = document.createElement('input');
              tokenInput.type = 'hidden';
              tokenInput.className = 'recaptcha_token';
              tokenInput.name = 'g-recaptcha-response';
              tokenInput.value = token;
              form.appendChild(tokenInput);
            });
          } else {
            document.querySelectorAll<HTMLInputElement>('form .recaptcha_token').forEach((input) => {
              input.value = token;
            });
          }
        });
        setTimeout(getReCaptchaToken, 30000);
      };
      (window as any).grecaptcha.enterprise.ready(getReCaptchaToken);
    };

    document.addEventListener('focusin', (e) => {
      if ((e.target as HTMLInputElement)?.matches('input, textarea, select')) {
        loadReCAPTCHA();
      }
    });
  }, [templateLoaded]);

  return null;
};

export default ReCAPTCHA;
