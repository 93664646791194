import Base from './Base';

interface ComponentCategoryProps {
  id?:    string;
  name?:  string;
}

export default class ComponentCategory extends Base {
  id?:    string;
  name?:  string;

  constructor(props: ComponentCategoryProps) {
    super(props);
    Object.assign(this, props);
  }
}
