import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function InstantContactButton() {
  if (!window.site?.instantContactForm || !window.site.instantContactForm.display) {
    return null;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [displayBackButton, setDisplayBackButton] = useState(false);

  // We need to listen for a message from the iframe to know when to display the back button.
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.type === 'instantContactInterstitialNavigated') {
        setDisplayBackButton(true);
      }
    });

    setTimeout(() => {
      const button = document.querySelector('.instant-contact-button');
      if (!button) {
        return;
      }

      button.style.display = 'block';

      const buttonWidth = button.clientWidth;
      if (buttonWidth) {
        button.style.marginTop = -(buttonWidth / 2) + 'px';
      }

      // Add some margin at the bottom of the <body> so that the last bit of content in the body doesn't get hidden
      // behind the instant contact button. We are doing this on an interval just in case the first time it runs
      // the button is not displayed yet or the height hasn't been computed, or maybe the <html> element doesn't
      // have .device-phone yet.
      const addBodyPadding = () => {
        if (document.documentElement.classList.contains('device-phone')) {
          document.body.style.marginBottom = `${button.clientHeight}px`;
        }
      };

      addBodyPadding();
      setInterval(addBodyPadding, 1000);
    }, 100);
  }, []);

  const url = new URL(window.site.instantContactForm.popupUrl, window.location.origin);
  url.searchParams.append('icf', 1);

  return (
    <>
      <Modal
        isOpen={modalOpen}
        unmountOnClose={false} // We want the user to be able to continue where they left off if they close the modal
        toggle={() => setModalOpen(false)}
        size="lg"
      >
        <ModalHeader
          toggle={() => setModalOpen(false)}
        >
          <a
            style={{ visibility: displayBackButton ? 'visible' : 'hidden' }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.history.back();
              setDisplayBackButton(false);
            }}
          >
            <i className="fa fal fa-arrow-left" />
          </a>
        </ModalHeader>
        <ModalBody>
          <iframe
            style={{ width: '100%' }}
            src={url.toString()}
          />
        </ModalBody>
      </Modal>

      <a
        href="#"
        id={window.site.instantContactForm.pageId}
        data-id={window.site.instantContactForm.pageId}
        data-header={window.site.instantContactForm.text}
        className="instant-contact-button"
        onClick={(e) => {
          e.preventDefault();
          setModalOpen(true);
        }}
      >
        <span className={`style-${window.site.instantContactForm.styleId}`}>
          {window.site.instantContactForm.text}
        </span>
      </a>
    </>
  );
}

export default InstantContactButton;
