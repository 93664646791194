import Base from './Base';
import { startCase } from 'lodash-es';

class Question {
  question?: string;
  answer?: string;
}

class ContentTopic extends Base {
  id?:                 string;
  site_id?:            string | number;
  type?:               string;
  name?:               string;
  page_name?:          string;
  client_comments?:    string;
  internal_info?:      string;
  primary_keywords?:   string;
  secondary_keywords?: string;
  related_keywords?:   string;
  questions?:          Question[];

  constructor(props: object) {
    super(props);
  }

  typeText(): string {
    if (this.type === 'product_service') {
      return 'Product/Service';
    }

    return startCase(this.type);
  }

  percentComplete(): number {
    let total = 0;
    let complete = 0;

    if (this.type === 'article') {
      this.questions?.forEach((question) => {
        total += 1;
        if (question.answer) {
          complete += 1;
        }
      });
    } else {
      // complete = 0;
    }

    return total === 0 ? 0 : Math.round((complete / total) * 100);
  }
}

export default ContentTopic;
