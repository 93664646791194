class Base {
  created_at?: Date;
  updated_at?: Date;

  _destroy?: boolean;

  constructor(props: { [key: string]: any }) {
    Object.assign(this, props);
  }
}

export default Base;
