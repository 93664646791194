import React, { useEffect, useRef } from 'react';
import { isFunction } from 'lodash-es';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import PageLayout from './PageLayout';
import CheckAdmin from './CheckAdmin';
import ReviewWidget from './Components/ReviewWidget';
import InstantContactButton from './InstantContactButton';
import SetIframeHeight from './SetIframeHeight';
import DeviceClass from './DeviceClass';
import StickyObserver from './StickyObserver';
import ClickableThumbnails from './ClickableThumbnails';
import HideEmptySectors from './HideEmptySectors';
import AddImageAltTags from './AddImageAltTags';
import GoogleAnalytics from './GoogleAnalytics';
import ReCAPTCHA from './ReCAPTCHA';
import BackToTop from './BackToTop';
import Popup from './Popup';
import AddCustomCode from './AddCustomCode';
import LinkClickListener from './LinkClickListener';
import ScrollToAnchorLink from './ScrollToAnchorLink';
import AddWebVitalsTracking from './AddWebVitalsTracking';
import HoistModals from './HoistModals';
import Parallax from './Parallax';
import { createStore, useAppSelector } from '../rootStore';
import { BrowserRouter as Router } from 'react-router-dom';
import { setTheme } from '../Utils';
import { createRoot } from 'react-dom/client';
import { PageDataObject } from '../ts/interfaces';
import { useUserConditional } from '../common/hooks';
import DeviceFrame from '../PublicAdmin/DeviceFrame';
import Navigator from '../PublicAdmin/Navigator';
import Loader from '../Dashboard/src/components/common/spinner';
import EmotionStyleSheetProvider from '../Dashboard/src/components/EmotionStyleSheetProvider';
import '../../stylesheets/application.css';

const PublicFacingSite: React.FC = () => {
  const template = useAppSelector((state: PageDataObject) => state.pageData.template);
  const site = useAppSelector((state: PageDataObject) => state.pageData.site);
  const page = useAppSelector((state: PageDataObject) => state.pageData.page);
  const pageVersion = useAppSelector((state: PageDataObject) => state.pageData.page_version);
  const user = useUserConditional();
  const modalRef = useRef(null);

  useEffect(() => {
    const domElement = document.getElementById('adminRoot');
    if (domElement) {
      const shadowRoot = domElement.shadowRoot || domElement.attachShadow({ mode: 'open' });
      // This component can be mounted multiple times, and calling createRoot multiple times causes problems, so we keep
      // a global reference to the react root node.
      if (!(window as any).adminRootReactNode) {
        (window as any).adminRootReactNode = createRoot(shadowRoot);
      }
    }
  }, []);

  useEffect(() => {
    setTheme('light');
  }, []);

  useEffect(() => {
    if (!(window as any).adminRootReactNode) {
      return;
    }

    // We don't show the navigator inside iframes, so we don't need all the associated assets.
    if (window !== window.top) {
      return;
    }

    if (!user) {
      return;
    }

    // We only support the navigator and other admin functionality on pages with grid templates.
    if (!template.grid) {
      return;
    }

    if (!isFunction(Loader) || !isFunction(EmotionStyleSheetProvider)) {
      // Something has gone wrong, we are logged into admin but we have loaded the public (non-admin) version of the JS bundle. We may be in the process of reloading the page, and after that happens we should have the correct bundle.
      return;
    }

    const domElement = document.getElementById('adminRoot');

    if (domElement) {
      const shadowRoot = domElement.shadowRoot || domElement.attachShadow({ mode: 'open' });

      const fontawesomeCSSEl = document.createElement('link');
      fontawesomeCSSEl.setAttribute('rel', 'stylesheet');
      fontawesomeCSSEl.setAttribute('href', 'https://kit.fontawesome.com/b3a98aa982.css');
      shadowRoot.appendChild(fontawesomeCSSEl);

      const bootstrapEl = document.createElement('link');
      bootstrapEl.setAttribute('rel', 'stylesheet');
      bootstrapEl.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
      shadowRoot.appendChild(bootstrapEl);

      const onPageEditinglinkEl = document.createElement('link');
      onPageEditinglinkEl.setAttribute('rel', 'stylesheet');
      onPageEditinglinkEl.setAttribute('href', '/assets/on-page-editing.css');
      shadowRoot.appendChild(onPageEditinglinkEl);

      const adminStylesLinkEl = document.createElement('link');
      adminStylesLinkEl.setAttribute('rel', 'stylesheet');
      adminStylesLinkEl.setAttribute('href', `/assets/build/admin/${ENV === 'development' ? 'dev' : 'prod'}/admin.css`);
      shadowRoot.appendChild(adminStylesLinkEl);

      const siteStylesLinkEl = document.createElement('link');
      siteStylesLinkEl.setAttribute('rel', 'stylesheet');
      siteStylesLinkEl.setAttribute('href', `/api/v1/sites/${site?.id}/pages/${page?.id}/page_versions/${pageVersion?.id}/stylesheet?prefix=true`);
      shadowRoot.appendChild(siteStylesLinkEl);

      (window as any).adminRootReactNode.render(
        <Router>
          <Provider store={createStore()}>
            <div className="theme-light">
              <div id="publicFacingAdminContainer" ref={modalRef} />
              <EmotionStyleSheetProvider stylesRoot={shadowRoot}>
                <Loader />
                <Navigator />
              </EmotionStyleSheetProvider>
            </div>
          </Provider>
        </Router>,
      );
    }
  }, [template, user]);

  useEffect(() => {
    (window as any).modalRef = modalRef;
  }, [modalRef]);

  return (
    <Router>
      <CheckAdmin />
      <AddWebVitalsTracking />
      <DeviceClass />
      <StickyObserver />
      <AddCustomCode />
      <ClickableThumbnails />
      <HideEmptySectors />
      <AddImageAltTags />
      <GoogleAnalytics />
      <ReCAPTCHA />
      <ToastContainer />
      <Popup />
      <LinkClickListener />
      <ScrollToAnchorLink />
      <HoistModals />
      <Parallax />
      <SetIframeHeight />
      {template?.grid && isFunction(DeviceFrame) && ( // DeviceFrame is only available in the public-admin bundle
        <DeviceFrame />
      )}

      <div className="device-emulator">
        <PageLayout />
        <InstantContactButton />
        <ReviewWidget />
        <BackToTop />
      </div>
    </Router>
  );
};

export default PublicFacingSite;
