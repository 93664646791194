import Base from './Base';

interface TemplateFragmentProps {
  id:            string | number;
  instance_id:   string | number;
  site_id:       string | number;
  html:          string;
  compiled_html: string;
  name:          string;
}

class TemplateFragment extends Base {
  id?:            string | number;
  instance_id?:   string | number;
  site_id?:       string | number;
  html?:          string;
  compiled_html?: string;
  name?:          string;

  constructor(props: TemplateFragmentProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default TemplateFragment;
