import Base from './Base';
import { ParseResultType, parseDomain } from 'parse-domain';

interface DomainProps {
  id?:                 string;
  platform?:           number;
  domain_name?:        string;
  is_primary?:         boolean;
  is_email?:           boolean;
  certificate_valid?:  boolean;
  certificate_expiry?: Date;
  site_id?:            string | number;
  redirect_page_id?:   string | number;
  serve_page_id?:      string | number;
}

class Domain extends Base {
  domain_name?:        string;
  id?:                 string;
  is_primary?:         boolean;
  is_email?:           boolean;
  platform?:           number;
  certificate_valid?:  boolean;
  certificate_expiry?: Date;
  site_id?:            string | number;
  redirect_page_id?:   string | number;
  serve_page_id?:      string | number;

  constructor(props: DomainProps) {
    super(props);
    Object.assign(this, props);
  }

  // Check if the domain is pointing at the correct CNAME. This should only be used for subdomains since root domains
  // can't point at CNAMEs. The DNS records returned from the API seem to be inconsistent, sometimes it will
  // recursively resolve only one level, sometimes more. We should never have CNAMEs from client domains pointing to
  // primary.webgenius.co.nz, but sometimes that's what we get back from the API, so we check for it.
  //
  // Also we have historically had a couple of variations of the domain that we point client CNAME records to, those are all included.
  isCNAMECorrect(data: any) {
    if (!data)
      return false;

    if (data.cname_records.length !== 1)
      return false;

    // TODO: Move these domains to an env variable
    const allowedCNAMEs = [
      'primary.webgenius.co.nz',
      'client1.wb.gs',
      'client1.webgenius.co.nz',
      'client.webgenius.co.nz',
    ];

    if (!allowedCNAMEs.includes(data.cname_records[0]))
      return false;

    return true;
  }

  isConfigurationCorrect(data: any) {
    if (!data)
      return false;

    if (data.is_cloudflare && !data.ping_check)
      return false;

    if (!data.is_cloudflare) {
      if (this.isRootDomain()) {
        if (data.a_records.length !== 1)
          return false;

        // TODO: Move the IP address to an env variable
        if (data.a_records[0] !== '112.109.67.241')
          return false;
      } else {
        if (!this.isCNAMECorrect(data))
          return false;
      }
    }

    return true;
  }

  isDKIMCorrect(data: any) {
    if (!data)
      return false;

    if (!data.dkim_cname_records || data.dkim_cname_records.length === 0)
      return false;

    // TODO: Move the DKIM domain to an env variable
    if (!data.dkim_cname_records.some((record: string) => record === 'client._domainkey.webgenius.co.nz'))
      return false;

    return true;
  }

  isSPFCorrect(data: any) {
    if (!data)
      return false;

    if (!data.txt_records || data.txt_records.length === 0)
      return false;

    // TODO: Move the _spf domain to an env variable
    if (!data.txt_records.some((record: string) => record.includes('include:_spf.webgenius.co.nz')))
      return false;

    return true;
  }

  // Google requires DMARC to be set up for bulk senders, but it doesn't require any specific policy, just that a
  // record exists.
  isDMARCCorrect(data: any) {
    if (!data)
      return false;

    if (!data.dmarc_records || data.dmarc_records.length === 0)
      return false;

    if (!data.dmarc_records.some((record: string) => record.includes('v=DMARC1')))
      return false;

    return true;
  }

  canUseForEmail(data: any) {
    return this.isDKIMCorrect(data) && this.isSPFCorrect(data) && this.isDMARCCorrect(data);
  }

  isRootDomain() {
    if (!this.domain_name)
      return false;

    const parseResult = parseDomain(this.domain_name);

    if (!parseResult || parseResult.type !== ParseResultType.Listed) {
      return false;
    }

    return parseResult.subDomains.length === 0;
  }
}

export default Domain;
