import Base from './Base';

export interface Child {
  id:                    string;
  title:                 string;
  name:                  string;
  depth:                 number;
  is_offline:            boolean;
  parent_id:             string | number;
  parent_ids:            Array<null | string>;
  instance_id:           string | number;
  site_id:               string;
  primary_url_id:        string;
  url:                   string;
  absolute_url:          string;
  page_type:             string;
  meta_tags:             any;
  is_in_nav:             boolean;
  is_indexable:          boolean;
  is_in_sitemap:         boolean;
  instant_contact_form:  boolean;
  page_version:          PageVersion;
  published_version_id:  null;
  display_review_widget: boolean;
  back_to_top:           boolean;
  popup_page_id:         null | string;
  popup_options:         PopupOptions;
  popup_visible_to:      string;
  redirect_page_id:      null;
  redirect_section_id:   null;
  redirect_url:          null | string;
  redirect_target:       string;
  children:              any[];
  expanded:              boolean;
  position:              number;
}

export interface PageVersion {
  name:            string;
  description:     string;
  seo_description: string;
  page_title:      string;
  copyright:       string;
  powered_by:      string;
  last_updated:    string;
  directory_link:  string;
  sitemap_link:    string;
  id:              string;
  page_id:         string;
  version_number:  number;
  template_id:     null | string;
  master_page_id:  null;
  created_at:      Date;
  updated_at:      Date;
  created_by:      null | string;
  modify_by:       null | string;
  is_published:    boolean;
  default_subject: string;
  preview_url:     string;
  publish_at:      null;
}

export interface PopupOptions {
  frequency?:     string;
  frame?:         boolean;
  width?:         string;
  trigger?:       string;
  trigger_value?: number;
}

interface PageProps {
  id?:                    string | number;
  title?:                 string;
  name?:                  string;
  depth?:                 number;
  is_offline?:            boolean;
  parent_id?:             string | number | null;
  parent_ids?:            string[];
  instance_id?:           string | number;
  site_id?:               string | number;
  primary_url_id?:        string | number;
  url?:                   string;
  page_type?:             string;
  meta_tags?:             any;
  is_in_nav?:             boolean;
  is_indexable?:          boolean;
  is_in_sitemap?:         boolean;
  instant_contact_form?:  boolean;
  page_version?:          PageVersion;
  published_version_id?:  null | string;
  display_review_widget?: boolean;
  back_to_top?:           boolean;
  popup_page_id?:         string | number | null;
  popup_options?:         PopupOptions;
  popup_visible_to?:      string;
  redirect_page_id?:      string | number | null;
  redirect_section_id?:   string | number | null;
  redirect_url?:          null | string;
  redirect_target?:       string;
  children?:              Child[];
  expanded?:              boolean;
  position?:              number;
}

class Page extends Base {
  id?:                    string | number;
  title?:                 string;
  name?:                  string;
  depth?:                 number;
  is_offline?:            boolean;
  parent_id?:             string | number | null;
  parent_ids?:            string[];
  instance_id?:           string | number;
  site_id?:               string | number;
  primary_url_id?:        string | number;
  url?:                   string;
  absolute_url?:          string;
  page_type?:             string;
  meta_tags?:             any;
  is_in_nav?:             boolean;
  is_indexable?:          boolean;
  is_in_sitemap?:         boolean;
  instant_contact_form?:  boolean;
  page_version?:          PageVersion;
  published_version_id?:  null | string;
  display_review_widget?: boolean;
  back_to_top?:           boolean;
  popup_page_id?:         string | number | null;
  popup_options?:         PopupOptions;
  popup_visible_to?:      string;
  redirect_page_id?:      string | number | null;
  redirect_section_id?:   string | number | null;
  redirect_url?:          null | string;
  redirect_target?:       string;
  children?:              Child[];
  expanded?:              boolean;
  position?:              number;
  orphan_page?:           boolean;
  sibling_page?:          string | number | null;
  copy_child_pages?:      boolean;

  constructor(props: PageProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Page;
