import { PaginationProps } from '../../ts/interfaces';
import Base from './Base';

interface TrashProps {
  instance_id?:     string | number;
  id?:              string;
  name:             string;
  record_type:      string;
  friendly_name:    string;
  deleted_by:       string;
  deleted_at:       Date;
}

class Trash extends Base {
  trashes?: TrashProps[];
  pagination?: PaginationProps;

  constructor(props) {
    super(props);
    this.trashes = props.trashes;
    this.pagination = props.pagination;
  }
}

export default Trash;
