import Base from './Base';

interface EmailSeriesEmail {
  id?:              string;
  delay?:           number;
  subject?:         string;
  page_email_id?:   string;
  email_series_id?: string;
}

interface EmailSeriesTrigger {
  id?:              string;
  trigger_type?:    string;
  trigger_id?:      string;
  type?:            string;
  email_series_id?: string;
  site_id?:         string;
  page_name?:       string;
}

interface EmailSeriesProps {
  instance_id?:                 string | number;
  site_id?:                     string | number;
  id?:                          string;
  name?:                        string;
  paused?:                      boolean;
  extra_emails?:                string[];
  from_email?:                  string;
  from_name?:                   string;
  restart_days?:                number;
  run_once?:                    boolean;
  email_series_emails?:         EmailSeriesEmail[];
  email_series_triggers?:       EmailSeriesTrigger[];
  email_series_triggers_list?:  string[];
}

class EmailSeries extends Base {
  instance_id?:                 string | number;
  site_id?:                     string | number;
  id?:                          string;
  name?:                        string;
  paused?:                      boolean;
  extra_emails?:                string[];
  from_email?:                  string;
  from_name?:                   string;
  restart_days?:                number;
  run_once?:                    boolean;
  email_series_emails?:         EmailSeriesEmail[];
  email_series_triggers?:       EmailSeriesTrigger[];
  email_series_triggers_list?:  string[];

  constructor(props: EmailSeriesProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default EmailSeries;
