import Base from './Base';

interface ButtonProps {
  id?:                string;
  site_id?:           string | number;
  name?:              string;
  css?:               CSS;
  hover_css?:         CSS;
  mobile_full_width?: boolean;
}

interface CSS {
  [key: string]: any;
}

class Button extends Base {
  id?:                string;
  site_id?:           string | number;
  name?:              string;
  css?:               CSS;
  hover_css?:         CSS;
  mobile_full_width?: boolean;

  constructor(props: ButtonProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Button;
