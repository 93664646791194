import { useEffect } from 'react';
import { setPageData } from '../common/api/apiSlice';
import { useAppDispatch, useAppSelector } from '../rootStore';

const DeviceClass: React.FC = () => {
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.pageData.device);

  const cl = document.documentElement.classList;

  const setDeviceClass = () => {
    const width = document.body.offsetWidth;

    if (width >= 1200) {
      dispatch(setPageData({ device: 'desktop' }));
    } else if (width > 576) {
      dispatch(setPageData({ device: 'tablet' }));
    } else {
      dispatch(setPageData({ device: 'phone' }));
    }
  };

  useEffect(() => {
    setInterval(setDeviceClass, 500);
  }, []);

  useEffect(() => {
    if (cl.contains('device-override')) {
      return;
    }

    if (device === 'desktop') {
      cl.add('device-desktop');
      cl.remove('device-tablet');
      cl.remove('device-phone');
    } else if (device === 'tablet') {
      cl.remove('device-desktop');
      cl.add('device-tablet');
      cl.remove('device-phone');
    } else if (device === 'phone') {
      cl.remove('device-desktop');
      cl.remove('device-tablet');
      cl.add('device-phone');
    }

  }, [device]);

  return null;
};

export default DeviceClass;
