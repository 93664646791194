import React, { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { setIframeHeight } from './Utils';
import PublicFacingSite from './PublicFacingSite';
import { Provider } from 'react-redux';
import { createStore } from './rootStore';

// Only importing the admin site so that we bring it into the public-facing (when logged into admin) bundle. In the
// non-logged in public-facing bundle it will be ignored.
// eslint-disable-next-line no-unused-vars
import AdminSite from './Dashboard/src/App';

// Legacy handler for setting iframe height, doesn't work cross-origin. Everything new should use postMessage.
window.setIframeHeight = setIframeHeight;

window.addEventListener('message', (message: MessageEvent) => {
  if (!message.isTrusted) return;

  const { iframeLocation, iframeHeight } = message.data as { iframeLocation?: string; iframeHeight?: number };
  if (!iframeLocation || typeof iframeHeight !== 'number') return;

  document.querySelectorAll<HTMLIFrameElement>('iframe').forEach(iframe => {
    if (iframe.src === iframeLocation) {
      iframe.style.height = `${iframeHeight + 1}px`;
    }
  });
});
const App: React.FC = () => (
  <StrictMode>
    <Provider store={createStore()}>
      <PublicFacingSite />
    </Provider>
  </StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
