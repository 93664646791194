import React from 'react';
import { useSite, useFireRenderEvent, useButtonsMax } from '../../common/hooks';

function ActionPageInstantContactInterstitial() {
  const site = useSite();

  useFireRenderEvent();
  const ref = useButtonsMax();

  if (!site) {
    // If we don't return anything here the sector might be hidden by HideEmptySectors.
    return (
      <div className="action-page" />
    );
  }

  const onNavigated = () => {
    window.top.postMessage({ type: 'instantContactInterstitialNavigated' }, '*');
  };

  return (
    <div
      ref={ref}
      className="action-page"
      style={{ textAlign: 'center' }}
    >
      <div className="mb-5">
        <span className={`style-${site.instant_contact_interstitial_text_style_id}`}>
          {site.instant_contact_intro_text}
        </span>
      </div>

      <p>
        <a
          href="/?Action=InstantContactChat&icf=1"
          data-button-style-id={site.instant_contact_interstitial_button_style_id}
          onClick={onNavigated}
        >
          <button className="m-1">Chat with our chatbot</button>
        </a>
        {' '}
        <a
          href={window.site.instantContactForm.formUrl}
          data-button-style-id={site.instant_contact_interstitial_button_style_id}
          onClick={onNavigated}
        >
          <button className="m-1">Fill out a form</button>
        </a>
      </p>
    </div>
  );
}

export default ActionPageInstantContactInterstitial;
