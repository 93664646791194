import Base from './Base';

interface SiteStyleOverrideProps {
  instance_id?:     string | number;
  site_id?:         string | number;
  id?:              string;
  all_styles?:      any;
  desktop_styles?:  any;
  tablet_styles?:   any;
  phone_styles?:    any;
  object_type?:     string;
  object_id?:       string;
  element?:         string;
  sector?:          number[];
  template_ids?:    number[];
}

class SiteStyleOverride extends Base {
  instance_id?:     string | number;
  site_id?:         string | number;
  id?:              string;
  all_styles?:      any;
  desktop_styles?:  any;
  tablet_styles?:   any;
  phone_styles?:    any;
  object_type?:     string;
  object_id?:       string;
  element?:         string;
  sector?:          number[];
  template_ids?:    number[];

  constructor(props: SiteStyleOverrideProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default SiteStyleOverride;
