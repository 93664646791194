import { isFunction } from 'lodash-es';
import { useUserConditional } from 'common/hooks';

// We are loading the dashboard purely to check if have loaded the admin version of the public JS bundle. We don't
// actually use the DashboardView component.
import DashboardView from '../Dashboard/src/views/dashboards/dashboardView';

// This component checks whether or not the user is logged into the admin interface. We send different versions of the
// JS bundle -- if they are logged into admin we send a much larger bundle which includes the admin interface.
const CheckAdmin: React.FC = () => {
  const setAdminCookie = (status: any) => {
    const date = new Date();
    date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
    const expires = date.toUTCString();
    document.cookie = 'wg_admin=' + (status ? '1' : '0') + '; expires=' + expires + '; path=/;';
  };

  const user = useUserConditional();

  if (user === false) {
    setAdminCookie(false);
    return;
  }

  if (window.top !== window.self) {
    // We are probably in an iframe. We can't really set the cookie here, and it doesn't really make sense to add the
    // admin functionality, so we just bail out.
    return;
  }

  if (user?.id) {
    setAdminCookie(true);

    if (!isFunction(DashboardView)) {
      // We are logged in but we have loaded the public (non-admin) version of the bundle. Now that the cookie is set,
      // we can reload the page and the server should serve the correct bundle.
      window.location.reload();
      return null;
    }
  }

  return null;
};

export default CheckAdmin;
