import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTurnDownRight } from '@fortawesome/pro-solid-svg-icons';
import { DateTime } from 'luxon';
import StarsRating from '../../Components/StarsRating';
import { createStore } from '../../../rootStore';
import { get, isEmpty } from 'lodash-es';
import ReviewAverage from '../../Components/ReviewAverage';

function SectionReviewList({ section, onClick }) {
  const { origin_id, review_count, review_average, include_average } = section;
  const store = createStore();
  const { pageData: { site, admin_domain } } = store.getState();
  const [page, setPage] = useState(1);
  const [reviews, setReviews] = useState(section?.reviews || []);
  const [loading, setLoading] = useState(false);

  const companyName = get(site, 'display_name', '');
  const companyAddressStreet = get(site, 'company_address_street', '');
  const companyAddressLocality = get(site, 'company_address_locality', '');
  const companyAddressRegion = get(site, 'company_address_region', '');
  const companyAddress = `${companyAddressStreet}, ${companyAddressLocality}, ${companyAddressRegion}`;

  const loadMore = () => {
    setLoading(!loading);
    setPage(page + 1);
    fetch(`https://${admin_domain}/api/v1/reviews?per_page=10&page=${page + 1}&site_id=${site?.id}`)
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          const { data } = res;
          setReviews([...reviews, ...data.reviews]);
          setLoading(false);
        }
      });
  };

  if (isEmpty(site)) {
    return <></>;
  }

  return (
    <>
      <a className="anchor" id={`anchor${origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionReviewList bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        <div className="review-outer">
          {include_average && review_average && (
            <div className="review-average">
              <div className="company-name me-2">{companyName}</div>
              <div className="company-address">{companyAddress}</div>
              <div className="rating-cont">
                <span className="rating-number">{review_average.toPrecision(2)}</span>
                <ReviewAverage rating={review_average} />
                <span className="review-count">{review_count} reviews</span>
              </div>
            </div>
          )}
          {reviews && reviews.map((review) => (
            <div
              className="review"
              key={review?.id}
              itemProp="review"
              itemScope
              itemType="https://schema.org/Review"
            >
              <div
                itemProp="itemReviewed"
                itemScope
                itemType="https://schema.org/LocalBusiness"
                style={{ display: 'none' }}
              >
                {/* TODO: image */}
                {/* <img itemProp="image" src="https://example.com/photos/1x1/seafood-restaurant.jpg" alt="Legal Seafood"/> */}
                <span itemProp="name">
                  {site.display_name}
                </span>
                <span itemProp="priceRange">
                  {site.price_range}
                </span>
                <span itemProp="telephone">
                  {isEmpty(site.displayed_phone_number) ? site.phone_number : site.displayed_phone_number}
                </span>
                <address
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                  style={{ display: 'none' }}
                >
                  <span itemProp="streetAddress">
                    {site.company_address_street}
                  </span>,
                  <span itemProp="addressLocality">
                    {site.company_address_locality}
                  </span>
                  <span itemProp="addressRegion">
                    {site.company_address_region}
                  </span>
                </address>
              </div>
              <div className="review-meta d-flex align-items-center">
                <div className="review-date">
                  <meta itemProp="datePublished" content={(new DateTime(review?.created_at)).toISODate()} />
                  {(new DateTime(review?.created_at)).toLocaleString(DateTime.DATE_MED)}
                </div>
                <div className="review-rating">
                  <span
                    itemProp="reviewRating"
                    itemScope
                    itemType="https://schema.org/Rating"
                    style={{ display: 'none' }}
                  >
                    <span itemProp="ratingValue">
                      {review?.rating}
                    </span>
                  </span>
                  <StarsRating fontSize="large" rating={review?.rating} />
                </div>
              </div>
              <div className="review-text" itemProp="reviewBody">{review?.text}</div>
              {review?.response && (
                <div className="review-response">
                  <span className="me-2">
                    <FontAwesomeIcon className="me-1" icon={faTurnDownRight} />
                    <i>Response:</i>
                  </span>
                  {review?.response}
                </div>
              )}
              <div className="review-attribution">
                <div className={`review-source review-source-${review?.platform}`} />
                <div className="reviewer-name" itemProp="author" itemScope itemType="https://schema.org/Person">
                  <span itemProp="name">
                    {review?.reviewer_name}
                  </span>
                </div>
                <div className="review-link">
                  <a href={review?.url || 'javascriptvoid:();'} target="_blank" rel="noreferrer">View review</a>
                </div>
              </div>
            </div>
          ))}
          {review_count && review_count > 10 && review_count > reviews.length && (
            <div className="review-footer">
              <Button onClick={() => loadMore()} color="link">
                {loading && <Spinner size="sm" />}
                {' '}
                See more reviews
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default SectionReviewList;
