import Base from './Base';

interface Row {
  id?:                                           string;
  instance_id?:                                  string | number;
  products?:                                     any[];
  primary_domain_id?:                            null | string;
  home_page_id?:                                 string;
  favicon_id?:                                   null;
  myob_id?:                                      null;
  billing_active?:                               boolean;
  billing_advisor_id?:                           null;
  campaign_budget_percentage?:                   number;
  wg_hosted?:                                    boolean;
  billing_monthly_fee?:                          number;
  billing_monthly_fee_ads?:                      number;
  billing_monthly_fee_seo?:                      number;
  billing_monthly_fee_ace?:                      number;
  billing_monthly_fee_gre?:                      number;
  billing_contact_name?:                         null;
  billing_company_name?:                         null;
  billing_emails?:                               null;
  billing_wfl_upgrade_date?:                     null;
  billing_related_sites?:                        null;
  directory_auto_included_site_ids?:             any[];
  directory_manually_included_site_ids?:         any[];
  directory_excluded_site_ids?:                  any[];
  broad_business_niche_ids?:                     any[];
  narrow_business_niche_ids?:                    any[];
  geo_area_id?:                                  null;
  link_directory_number?:                        number;
  enable_phone_breakpoint?:                      boolean;
  enable_tablet_breakpoint?:                     boolean;
  google_tag_manager_id?:                        null;
  google_analytics_service_account?:             null;
  google_analytics_account_id?:                  null;
  google_analytics_property_id?:                 null;
  google_analytics_data_stream_id?:              null;
  google_ads_account_id?:                        null;
  recaptcha_site_key?:                           string;
  recaptcha_bot_detection_threshold?:            number;
  google_site_verification?:                     any[];
  label?:                                        null | string;
  value?:                                        string;
  site_map_master_page_id?:                      null;
  privacy_policy_master_page_id?:                null;
  site_search_master_page_id?:                   null;
  unsubscribe_master_page_id?:                   null;
  business_directory_master_page_id?:            null;
  popup_page_id?:                                null;
  popup_options?:                                any;
  popup_visible_to?:                             string;
  privacy_policy_text?:                          string;
  time_zone?:                                    string;
  display_name?:                                 string;
  copyright_year?:                               number;
  in_directory?:                                 boolean;
  site_description?:                             string;
  profile_info?:                                 any;
  company_address_street?:                       null | string;
  company_address_locality?:                     null | string;
  company_address_region?:                       null | string;
  phone_number?:                                 null;
  displayed_phone_number?:                       null;
  tracking_phone_numbers?:                       any[];
  geo_coords?:                                   null;
  orientation_statement?:                        string;
  enable_instant_contact_form?:                  boolean;
  text_for_instant_contact_form?:                string;
  background_for_button?:                        string;
  hover_background_for_button?:                  string;
  instant_contact_form_days_of_week?:            any[];
  instant_contact_form_start_time?:              Date | null;
  instant_contact_form_end_time?:                Date | null;
  text_style_for_instant_contact_form_id?:       null;
  instant_contact_page_id?:                      string;
  enable_instant_contact_form_form?:             boolean;
  enable_instant_contact_form_chat?:             boolean;
  instant_contact_master_page_id?:               null;
  instant_contact_intro_text?:                   string;
  instant_contact_interstitial_button_style_id?: null;
  instant_contact_interstitial_text_style_id?:   null;
  instant_contact_chat_text_style_id?:           null;
  guaranteed_keyword_phrases?:                   any[];
  seo_visibility_model?:                         number;
  initial_visibility_points?:                    null;
  initial_visibility_percentage?:                null;
  default_form_email_addresses?:                 any[];
  default_form_email_subject?:                   string;
  default_form_mobile_number?:                   string;
  price_range?:                                  string;
  microdata_image_id?:                           null;
  microdata_logo_id?:                            null;
  colors?:                                       Color[];
  majestic_trust_flow_home?:                     null;
  majestic_citation_flow_home?:                  null;
  review_binary?:                                boolean;
  review_star_labels?:                           string[];
  review_pages?:                                 any;
  review_email_addresses?:                       any[];
  review_threshold?:                             number;
  review_list_page_id?:                          null;
  libraries?:                                    Libraries;
  library_version?:                              LibraryVersion;
  chatbot_assistant_id?:                         null;
  chatbot_initial_message?:                      string;
  chatbot_style?:                                string;
  chatbot_persona_name?:                         string;
  chatbot_persona_icon_id?:                      null;
  chatbot_extra_instructions?:                   string;
  chatbot_data_source?:                          string;
  chatbot_text?:                                 string;
  chatbot_sectors_to_exclude?:                   any[];
  chatbot_pages_to_exclude_ids?:                 any[];
}

export interface Color {
  id?:          number;
  label?:       string;
  hex?:         string;
  is_gradient?: boolean;
  gradient?:    string;
  hex_no_hash?: string;
}

export interface Libraries {
  Bootstrap:   Bootstrap[];
  FontAwesome: FontAwesome[];
  jQuery:      JQuery[];
}

interface Bootstrap {
  name:          string;
  js_url:        string;
  js_integrity:  string;
  css_url:       string;
  css_integrity: string;
}

interface FontAwesome {
  name: string;
  url:  string;
}

interface JQuery {
  name:      string;
  url:       string;
  integrity: string;
}

export interface LibraryVersion {
  Bootstrap:   string;
  jQuery:      string;
  FontAwesome: string;
}

interface KeyObjectValue {
  [key: string]: any;
}

export default class Reports extends Base {
  rows:     Row[];
  rowCount: number;

  constructor(props) {
    super(props);
    this.rows = props.rows;
    this.rowCount = props.rowCount;
  }
}

export class ReportConfig extends Base {
  name?:        string;
  columns?:     KeyObjectValue[];
  data?:        KeyObjectValue[];
  filter?:      KeyObjectValue[];
  post_filter?: KeyObjectValue[];
  map_values?:  KeyObjectValue[];

  constructor(props) {
    super(props);
    Object.assign(this, props);
  }
}
