import { useEffect } from 'react';

// It is handy to be able to output a modal box anywhere in the HTML document, but if a modal is somewhere deep in the
// DOM, there is a risk that the backdrop won't work properly because it's inside an element with fixed or relative
// positioning, so we just move them all to the <body> element.
// https://stackoverflow.com/a/11788713/2272240
const HoistModals: React.FC = () => {
  useEffect(() => {
    document.addEventListener('render', () => {
      document.querySelectorAll('.modal').forEach((modal) => {
        if (modal.classList.contains('no-hoist')) {
          return;
        }

        document.body.appendChild(modal);
      });
    });
  }, []);

  return null;
};

export default HoistModals;
