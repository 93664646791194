import Base from './Base';

interface Vars {
  default?:     boolean | string;
  name?:        string;
  html_name?:   string;
  type?:        string;
  char_limit?:  number;
  sub_item?:    string;
  scss_name?:   string;
}

interface ComponentProps {
  id?:                    string;
  type?:                  string;
  name?:                  string;
  html?:                  string;
  scss?:                  string;
  boot_func?:             string;
  published?:             boolean;
  deprecated?:            boolean;
  sub_items?:             string[];
  js_urls?:               string[];
  css_urls?:              any[];
  html_vars?:             Vars[];
  scss_vars?:             Vars[];
  full_size?:             boolean;
  component_category_id?: string;
}

export default class Component extends Base {
  id?:                    string;
  type?:                  string;
  name?:                  string;
  html?:                  string;
  scss?:                  string;
  boot_func?:             string;
  published?:             boolean;
  deprecated?:            boolean;
  sub_items?:             string[];
  js_urls?:               string[];
  css_urls?:              any[];
  html_vars?:             Vars[];
  scss_vars?:             Vars[];
  full_size?:             boolean;
  component_category_id?: string;

  constructor(props: ComponentProps) {
    super(props);
    Object.assign(this, props);
  }
}
