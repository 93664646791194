import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sector from './Sector';
import macroReplacerNode from './macroReplacerNode';
import { setPageData } from '../common/api/apiSlice';

const TemplateGrid = ({ template }) => {
  const dispatch = useDispatch();

  const sectors = useSelector((state) => state.pageData.sector);

  const cleanLabel = (l) => l.toString().trim().replace(/\s+/g, '');

  const allSectors = [].concat(template.desktop_layout, template.tablet_layout, template.phone_layout);
  const sectorLabels = new Set(allSectors.map((s) => s.label));
  const overlaySectors = allSectors.filter((s) => s.overlay).map((s) => s.label);

  useEffect(macroReplacerNode);
  useEffect(() => { dispatch(setPageData({ template_loaded: true })); }, []);

  const ParallaxWrapper = (props) => {
    if (props.enabled) {
      return (
        <div className="parallax-wrapper" style={{ gridArea: props.gridArea }}>
          {props.children}
        </div>
      );
    } else {
      return props.children;
    }
  };

  return (
    <div className="page-wrapper">
      {[...sectorLabels].map((sectorLabel) => {
        const sector = sectors.find((s) => s.label === sectorLabel);
        if (!sector) {
          return null;
        }

        const labelNoSpaces = cleanLabel(sector.label);

        const classes = ['wrapper', `wrapper-${labelNoSpaces}`];
        const overlayClasses = [];

        if (template.desktop_layout.some((s) => s.sticky && cleanLabel(s.label) == sector.label)) {
          classes.push('sticky-desktop');
        }
        if (template.tablet_layout.some((s) => s.sticky && cleanLabel(s.label) == sector.label)) {
          classes.push('sticky-tablet');
        }
        if (template.phone_layout.some((s) => s.sticky && cleanLabel(s.label) == sector.label)) {
          classes.push('sticky-phone');
        }

        if (template.desktop_layout.some((s) => s.parallax && cleanLabel(s.label) == sector.label)) {
          overlayClasses.push('parallax-desktop', 'parallax-foreground');
          classes.push('parallax-desktop', 'parallax-background');
        }
        if (template.tablet_layout.some((s) => s.parallax && cleanLabel(s.label) == sector.label)) {
          overlayClasses.push('parallax-tablet', 'parallax-foreground');
          classes.push('parallax-tablet', 'parallax-background');
        }
        if (template.phone_layout.some((s) => s.parallax && cleanLabel(s.label) == sector.label)) {
          overlayClasses.push('parallax-phone', 'parallax-foreground');
          classes.push('parallax-phone', 'parallax-background');
        }

        // Our grid system works with grid-template-areas, so grid labels need to be a <custom-ident>, and they cannot be just a number.
        const gridArea = labelNoSpaces.match(/^[\d-]/) ? `a-${labelNoSpaces}` : labelNoSpaces;

        const overlay = overlaySectors.includes(sector.label) && (
          <div
            id={`wrapper-${labelNoSpaces}-Overlay`}
            className={`wrapper wrapper-overlay wrapper-${labelNoSpaces}-Overlay ${overlayClasses.join(' ')}`}
            style={{ gridArea }}
            data-sector={sector.label}
          >
            <a id={`anchor-${labelNoSpaces}-Overlay`} className="anchor" />

            <div className={`sector sector-${labelNoSpaces}-Overlay`}>
              <Sector sector={{ label: `${labelNoSpaces}-Overlay` }} />
            </div>
          </div>
        );

        return (
          <Fragment key={sector.label}>
            <ParallaxWrapper
              enabled={classes.some((c) => c === 'parallax-desktop' || c === 'parallax-tablet' || c === 'parallax-phone')}
              gridArea={gridArea}
            >
              <div
                id={`wrapper-${labelNoSpaces}`}
                className={classes.join(' ')}
                style={{ gridArea }}
                data-sector={sector.label}
              >
                <a id={`anchor-${labelNoSpaces}`} className="anchor" />

                <div className={`sector sector-${labelNoSpaces} ${sector.is_primary ? 'primary-sector' : ''}`}>
                  <Sector sector={sector} />
                </div>
              </div>

              {overlay}
            </ParallaxWrapper>
          </Fragment>
        );
      })}
    </div>
  );
}

export default TemplateGrid;
