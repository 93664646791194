import Base from './Base';

interface SectorProps {
  id?:                      string;
  label?:                   string;
  order?:                   number;
  background?:              string;
  is_primary?:              boolean;
  template_id?:             string;
  siteId?:                  string | number;
  pageId?:                  string | number;
  pageVersionId?:           string | number;
  instance_id?:             string | number;
  master_content_position?: string;
}

class Sector extends Base {
  id?:                      string;
  label?:                   string;
  order?:                   number;
  background?:              string;
  is_primary?:              boolean;
  template_id?:             string;
  siteId?:                  string | number;
  pageId?:                  string | number;
  pageVersionId?:           string | number;
  instance_id?:             string | number;
  master_content_position?: string;

  constructor(props: SectorProps) {
    super(props);
    Object.assign(this, props);
  }
}

export default Sector;
