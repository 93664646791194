import { useEffect } from 'react';
import { useAppSelector } from '../rootStore';
import { PageDataObject } from '../ts/interfaces';

const StickyObserver: React.FC = () => {
  const dc = document.documentElement.classList;
  const template = useAppSelector((state: PageDataObject) => state.pageData.template);

  const getStickyThreshold = (style: CSSStyleDeclaration) => {
    const sectorName = style.gridArea;
    if (!sectorName) return 0;

    let device;
    if (dc.contains('device-desktop')) device = 'desktop';
    if (dc.contains('device-tablet')) device = 'tablet';
    if (dc.contains('device-phone')) device = 'phone';

    if (!device) return 0;

    const layout = template[`${device}_layout`];
    if (!layout) return 0;

    const sector = layout.find((s: { label: string; }) => s.label === sectorName);
    if (!sector) return 0;

    if (typeof sector.sticky_threshold !== 'number') return 0;

    return sector.sticky_threshold;
  };

  useEffect(() => {
    // Add "sticky-stuck" class to all position: sticky elements which are "stuck".

    let stickyElements: any[] | NodeListOf<Element> = [];
    let ticking = false;
    let justRemoved = false;
    let timeout;

    document.addEventListener('render', () => {
      stickyElements = document.querySelectorAll('.sticky-desktop, .sticky-tablet, .sticky-phone');
    });

    // If we want the sticky element to become stuck based on the top of an element inside the sticky sector, we can
    // give an element the ID of "stickyTopElement".
    const setTop = () => {
      const topEl = document.getElementById('stickyTopElement');
      if (!topEl) {
        return;
      }

      let top = topEl.getBoundingClientRect().top;
      if (top === 0) {
        return;
      }
      top += window.scrollY;
      stickyElements.forEach((el) => {
        el.style.top = `${0 - top}px`;
      });
    };
    window.addEventListener('resize', setTop);
    document.addEventListener('render', setTop);
    setTop();

    // Things move around after the page is loaded, mostly because of Google Fonts, so we update the top property a few
    // times using an exponential backoff.
    let delay = 10;
    const setTopDelayed = () => {
      setTop();
      delay = delay * 1.1;
      window.setTimeout(setTopDelayed, delay);
    }
    setTopDelayed();

    const updateStickyElements = () => {
      stickyElements.forEach((el) => {
        const style = getComputedStyle(el);

        if (style.display === 'none') return;

        const threshold = getStickyThreshold(style);

        const isStuck = el.classList.contains('sticky-stuck');

        const rect = el.getBoundingClientRect();

        if (rect.y < 1 && !isStuck && !justRemoved) {
          el.classList.add('sticky-stuck');
        } else if ((rect.y >= threshold || window.scrollY < 1) && isStuck) {
          el.classList.remove('sticky-stuck');
          justRemoved = true;
          // The 500 number below must be at least as big as the CSS transition time. This is currently set in custom CSS
          // and I (MP) can't be bothered creating a new setting to set this number, but it should work fine in most situations.
          timeout = setTimeout(() => justRemoved = false, 500);
        }
      });
    };

    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateStickyElements();
          ticking = false;
        });

        ticking = true;
      }
    });
  }, []);

  return null;
};

export default StickyObserver;
