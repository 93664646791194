import getMacros from './getMacros';

// Replace macros in a DOM node
const macroReplacerNode = (root) => {
  if (typeof root === 'undefined') {
    root = document.body;
  }

  const macros = getMacros();

  let node;
  const allNodes = [];
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);
  while (node = walker.nextNode()) {
    if (node.parentElement instanceof HTMLScriptElement) {
      continue;
    }
    allNodes.push(node);
  }

  // Some macros need to allow putting HTML in the replacement, but some don't -- like anything that allows
  // user-inputted text. The macros that don't support HTML are easy, we just set the node's textContent.
  macros.filter((m) => m[2]).forEach(([macro, replacement]) => {
    allNodes.forEach((node) => {
      node.textContent = node.textContent.replaceAll(macro, replacement);
    });
  });

  // The macros that allow HTML are a bit more tricky, we have to create a new element to hold the HTML. We also need
  // to iterate the nodes using a for loop because we need to add the replacement nodes to the array while iterating.
  for (let i = 0; i < allNodes.length; i++) {
    const node = allNodes[i];

    macros.filter((m) => !m[2]).forEach(([macro, replacement]) => {
      if (!node.parentNode) {
        return;
      }

      if (!node.textContent.match(macro)) {
        return;
      }

      const replacementNode = document.createElement('span');
      allNodes.push(replacementNode);
      replacementNode.innerHTML = (node.nodeType === 1 ? node.innerHTML : node.textContent).replaceAll(macro, replacement);
      node.parentNode.insertBefore(replacementNode, node);
      node.parentNode.removeChild(node);
    });
  }
};

export default macroReplacerNode;
