import React from 'react';

function SetIframeHeight() {
  if (window === window.top) {
    return;
  }

  const setIframeHeight = () => top.postMessage({
    iframeLocation: window.location.href,
    iframeHeight: document.body.clientHeight,
  }, '*');

  setIframeHeight();

  // And run it every so often just in case things inside the iframe get resized.
  setInterval(setIframeHeight, 250);

  return <></>;
}

export default SetIframeHeight;
