import React from 'react';
import { sectionObject } from '../../../Utils';
import { useSelector } from 'react-redux';
import { find } from 'lodash-es';

function SectionMirror({ section, onClick }) {
  const allSections = useSelector((state) => state.pageData.section);
  const page = useSelector((state) => state.pageData.page);
  const mirrorOf = find(allSections, (s) => s.origin_id === section.mirror_of_id);

  if (!mirrorOf) {
    return <></>;
  }

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionMirror bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        {sectionObject(page, mirrorOf, onClick)}
      </section>
    </>
  );
}

export default SectionMirror;
