import React, { useState } from 'react';
import LowRatingFormHTML from './LowRatingFormHTML';
import HighRatingFormHTML from './HighRatingFormHTML';
import StarsRating from '../../Components/StarsRating';
import { useAppSelector } from '../../../rootStore';
import './review_engine.css';
interface SectionReviewFormProps {
  section: any;
  onClick: (section: any) => void;
}

const SectionReviewForm: React.FC<SectionReviewFormProps> = ({ section, onClick }) => {
  const site = useAppSelector((state) => state.pageData.site);
  const [lowRatingFormModal, setLowRatingFormModal] = useState(false);
  const [highRatingFormModal, setHighRatingFormModal] = useState(false);

  const onHandleClickStar = (sentiment: string) => {
    if (sentiment === 'positive') {
      setLowRatingFormModal(false);
      setHighRatingFormModal(!highRatingFormModal);
    } else {
      setHighRatingFormModal(false);
      setLowRatingFormModal(!lowRatingFormModal);
    }
  };

  const renderHTML = () => {
    if (site && site?.review_binary) {
      return (
        <div className="review-binary-options">
          <img src="/assets/review-icon-dislike.png" data-sentiment="negative" onClick={() => onHandleClickStar('negative')} />
          <img src="/assets/review-icon-like.png" data-sentiment="positive" onClick={() => onHandleClickStar('positive')} />
        </div>
      );
    }
    return (
      <div className="star-rating">
        <div className="star-rating__wrap">
          <StarsRating onHandleClickStar={onHandleClickStar} textHolder={true} />
        </div>
      </div>
    );
  };

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionReviewForm bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || (window as any).wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        {renderHTML()}
      </section>
      {lowRatingFormModal && (
        <LowRatingFormHTML modal={lowRatingFormModal} toggle={setLowRatingFormModal} section={section} />
      )}
      {highRatingFormModal && (
        <HighRatingFormHTML modal={highRatingFormModal} toggle={setHighRatingFormModal} />
      )}
    </>
  );
};

export default SectionReviewForm;
