import React, { useState } from 'react';
import StarsRating from '../../Components/StarsRating';
import { createStore } from '../../../rootStore';
import { getCookie, setCookie } from '../../../Utils';

function ReviewWidget() {
  const store = createStore();
  const { pageData: { page: { display_review_widget }, site, review: reviews } } = store.getState();
  const [closed, setClosed] = useState(false);
  const cookieName = 'REVIEW_WIDGET_CLOSED';

  // Don't want to show the widget inside iframes.
  if (window !== window.top) {
    return <></>;
  }

  if (getCookie(cookieName) || closed) {
    return <></>;
  }

  if (!display_review_widget || !reviews || reviews.length === 0) {
    return <></>;
  }

  const closeReviewWidget = () => {
    setCookie(cookieName, '1', 365, '/');
    setClosed(true);
  };

  return (
    <div className="review-widget">
      <ul>
        {reviews.map((review) => (
          <li key={review.id} className="review-widget-item">
            <a
              className="more"
              rel="nofollow noreferrer"
              href={site?.review_list_page_url || review.url}
              target={site?.review_list_page_id ? '_top' : '_blank'}
            >
              <div className="review-widget-container">
                <div className={`review-widget-image review-source-${review.platform}`}></div>
                <div className="review-widget-content">
                  <div className="review-widget-rating">
                    <span className="stars d-flex"><StarsRating fontSize="large" rating={review?.rating} /></span>
                  </div>
                  {review?.text && (
                    <div className="review-widget-text">
                      <div className="review-widget-text-inner">
                        {review?.text.length > 60 ? `${review?.text.substring(0, 60)}...more` : review?.text}
                      </div>
                    </div>
                  )}
                  <div className="review-widget-reviewer">
                    <span>{review.reviewer_name}</span>
                  </div>
                </div>
                <span
                  className="review-widget-close"
                  onClick={(e) => {
                    e.preventDefault();
                    closeReviewWidget();
                  }}
                >
                  ×
                </span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ReviewWidget;
